@charset "UTF-8";

.textCenter{
    text-align: center;
}

.textLeft{
    text-align: left;
}

.textRight{
    text-align: right;
}

.whiteSpace{
    &.noWrap{
        white-space: nowrap;
    }
    &.preWrap{
        white-space: pre-wrap;
    }
}

.ellipsis{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}