.flex {
    display: flex;
    &.column {
      flex-direction: column;
    }
    &.hrtlCenter {
      justify-content: center;
    }
    &.vrtlCenter {
      align-items: center;
    }
    &.perfectCenter {
      justify-content: center;
      align-items: center;
    }
    &.wrap {
      flex-wrap: wrap;
    }
    &.noWrap {
      flex-wrap: nowrap;
    }
    &.spaceBetween {
      justify-content: space-between;
    }
    &.spaceAround {
      justify-content: space-around;
    }
    &.start {
      align-items: flex-start;
    }
    &.end {
      align-items: flex-end;
    }
    &.flexEnd {
      justify-content: flex-end;
    }
    .noShrink {
      flex-shrink: 0;
    }
    &.noShrink {
      flex-shrink: 0;
    }
    &.grow {
      flex-grow: 1;
    }
    &.baseline {
      align-items: baseline;
    }
    &.spaceEvenly {
      justify-content: space-evenly;
    }
    .grow {
      flex-grow: 1;
    }
    .none {
      flex: none;
    }
}  