:root {
  --primary-color: #16192A;
  --secondary-color: #FFD65A;
  --purple: #6058EA;
  --white: #fff;
  --primary: #FFD65A;
  --gray: #333333;
  --transparent-black: #33333367;
  --input-container-color: #16192A;
  --success-color: #4bb543;
  --error-color: #f44336;
}

html {
  font-size: 8px;
  scroll-behavior: smooth;
  @media only screen and (min-width: 1300px) {
    font-size: 9px;
  }
  @media only screen and (min-width: 1400px) {
    font-size: 9px;
  }
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body,
#app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  scroll-behavior: smooth;
}

button,
a {
  cursor: pointer;
}

button,
input {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#app {
  font-size: 1.5rem;
}

.absolute {
  position: absolute;
  &.hrtlCenter {
    left: 50%;
    transform: translateX(-50%);
  }
  &.hrtlCenter {
    top: 50%;
    transform: translateY(-50%);
  }
  &.perfectCenter {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.relative {
  position: relative;
  &.hrtlCenter {
    left: 50%;
    transform: translateX(-50%);
  }
  &.hrtlCenter {
    top: 50%;
    transform: translateY(-50%);
  }
  &.perfectCenter {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

p {
  margin: 0;
  padding: 0;
}